/* tslint:disable */
/* eslint-disable */
/**
 * Love H API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AdminUploadFileResponseDto
 */
export interface AdminUploadFileResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminUploadFileResponseDto
   */
  fileId: string;
}
/**
 *
 * @export
 * @interface CharityAndAmount
 */
export interface CharityAndAmount {
  /**
   *
   * @type {string}
   * @memberof CharityAndAmount
   */
  charityName: string;
  /**
   *
   * @type {number}
   * @memberof CharityAndAmount
   */
  amount: number;
}
/**
 *
 * @export
 * @interface CharityDto
 */
export interface CharityDto {
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  charityId: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  fontColorHex: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  bgColorHex: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  fileId: string;
  /**
   *
   * @type {FileDto}
   * @memberof CharityDto
   */
  logo: FileDto;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  termsAndConditions: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  thankYouText: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  pageTwoText: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  externalFirebaseId: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  address: string;
  /**
   *
   * @type {number}
   * @memberof CharityDto
   */
  maxSaleAmount: number;
  /**
   *
   * @type {number}
   * @memberof CharityDto
   */
  minSaleAmount: number;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  deactivatedAt: string;
  /**
   *
   * @type {string}
   * @memberof CharityDto
   */
  flow: CharityDtoFlowEnum;
}

export const CharityDtoFlowEnum = {
  Regular: 'REGULAR',
  Plan: 'PLAN',
  Ssm: 'SSM',
  Rfsu: 'RFSU',
} as const;

export type CharityDtoFlowEnum = (typeof CharityDtoFlowEnum)[keyof typeof CharityDtoFlowEnum];

/**
 *
 * @export
 * @interface CharityWithControlQuestionsDto
 */
export interface CharityWithControlQuestionsDto {
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  charityId: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  fontColorHex: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  bgColorHex: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  fileId: string;
  /**
   *
   * @type {FileDto}
   * @memberof CharityWithControlQuestionsDto
   */
  logo: FileDto;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  termsAndConditions: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  thankYouText: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  pageTwoText: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  externalFirebaseId: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  address: string;
  /**
   *
   * @type {number}
   * @memberof CharityWithControlQuestionsDto
   */
  maxSaleAmount: number;
  /**
   *
   * @type {number}
   * @memberof CharityWithControlQuestionsDto
   */
  minSaleAmount: number;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  deactivatedAt: string;
  /**
   *
   * @type {string}
   * @memberof CharityWithControlQuestionsDto
   */
  flow: CharityWithControlQuestionsDtoFlowEnum;
  /**
   *
   * @type {Array<ControlQuestionDto>}
   * @memberof CharityWithControlQuestionsDto
   */
  controlQuestions: Array<ControlQuestionDto>;
}

export const CharityWithControlQuestionsDtoFlowEnum = {
  Regular: 'REGULAR',
  Plan: 'PLAN',
  Ssm: 'SSM',
  Rfsu: 'RFSU',
} as const;

export type CharityWithControlQuestionsDtoFlowEnum =
  (typeof CharityWithControlQuestionsDtoFlowEnum)[keyof typeof CharityWithControlQuestionsDtoFlowEnum];

/**
 *
 * @export
 * @interface CityAndSales
 */
export interface CityAndSales {
  /**
   *
   * @type {string}
   * @memberof CityAndSales
   */
  city: string;
  /**
   *
   * @type {number}
   * @memberof CityAndSales
   */
  amountOfSales: number;
}
/**
 *
 * @export
 * @interface ControlQuestionDto
 */
export interface ControlQuestionDto {
  /**
   *
   * @type {string}
   * @memberof ControlQuestionDto
   */
  controlQuestionId: string;
  /**
   *
   * @type {string}
   * @memberof ControlQuestionDto
   */
  question: string;
  /**
   *
   * @type {string}
   * @memberof ControlQuestionDto
   */
  charityId: string;
}
/**
 *
 * @export
 * @interface CreateCharityRequestDto
 */
export interface CreateCharityRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  fontColorHex: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  bgColorHex: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  termsAndConditions: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  thankYouText: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  pageTwoText: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  maxSaleAmount: string;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  minSaleAmount: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateCharityRequestDto
   */
  controlQuestions: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateCharityRequestDto
   */
  flow: CreateCharityRequestDtoFlowEnum;
}

export const CreateCharityRequestDtoFlowEnum = {
  Regular: 'REGULAR',
  Plan: 'PLAN',
  Ssm: 'SSM',
  Rfsu: 'RFSU',
} as const;

export type CreateCharityRequestDtoFlowEnum =
  (typeof CreateCharityRequestDtoFlowEnum)[keyof typeof CreateCharityRequestDtoFlowEnum];

/**
 *
 * @export
 * @interface CreateCustomerDto
 */
export interface CreateCustomerDto {
  /**
   *
   * @type {string}
   * @memberof CreateCustomerDto
   */
  ssid: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerDto
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerDto
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerDto
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerDto
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerDto
   */
  phone?: string;
}
/**
 *
 * @export
 * @interface CreateExportRequestDto
 */
export interface CreateExportRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreateExportRequestDto
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof CreateExportRequestDto
   */
  to: string;
  /**
   *
   * @type {string}
   * @memberof CreateExportRequestDto
   */
  charityName: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateExportRequestDto
   */
  includePdfs: boolean;
}
/**
 *
 * @export
 * @interface CreatePaymentDto
 */
export interface CreatePaymentDto {
  /**
   *
   * @type {string}
   * @memberof CreatePaymentDto
   */
  saleId: string;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentDto
   */
  type: CreatePaymentDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentDto
   */
  publicKey?: string;
}

export const CreatePaymentDtoTypeEnum = {
  Adyen: 'PLAN_ADYEN',
  Avi: 'PLAN_AVI',
} as const;

export type CreatePaymentDtoTypeEnum = (typeof CreatePaymentDtoTypeEnum)[keyof typeof CreatePaymentDtoTypeEnum];

/**
 *
 * @export
 * @interface CreatePaymentResponseDto
 */
export interface CreatePaymentResponseDto {
  /**
   *
   * @type {string}
   * @memberof CreatePaymentResponseDto
   */
  paymentId: string;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentResponseDto
   */
  status: CreatePaymentResponseDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentResponseDto
   */
  externalPaymentId: string | null;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentResponseDto
   */
  saleId: string;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentResponseDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentResponseDto
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentResponseDto
   */
  type: CreatePaymentResponseDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreatePaymentResponseDto
   */
  checkoutUrl: string | null;
}

export const CreatePaymentResponseDtoStatusEnum = {
  Error: 'ERROR',
  Pending: 'PENDING',
  Done: 'DONE',
} as const;

export type CreatePaymentResponseDtoStatusEnum =
  (typeof CreatePaymentResponseDtoStatusEnum)[keyof typeof CreatePaymentResponseDtoStatusEnum];
export const CreatePaymentResponseDtoTypeEnum = {
  Adyen: 'PLAN_ADYEN',
  Avi: 'PLAN_AVI',
} as const;

export type CreatePaymentResponseDtoTypeEnum =
  (typeof CreatePaymentResponseDtoTypeEnum)[keyof typeof CreatePaymentResponseDtoTypeEnum];

/**
 *
 * @export
 * @interface CreateSaleDto
 */
export interface CreateSaleDto {
  /**
   *
   * @type {number}
   * @memberof CreateSaleDto
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof CreateSaleDto
   */
  bankAccount: string;
  /**
   *
   * @type {string}
   * @memberof CreateSaleDto
   */
  clearingNumber: string;
  /**
   *
   * @type {string}
   * @memberof CreateSaleDto
   */
  customerSsid: string;
  /**
   *
   * @type {number}
   * @memberof CreateSaleDto
   */
  volunteerId: number;
  /**
   *
   * @type {string}
   * @memberof CreateSaleDto
   */
  signatureDataUrl: string;
}
/**
 *
 * @export
 * @interface CreateVolunteerRequestDto
 */
export interface CreateVolunteerRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreateVolunteerRequestDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof CreateVolunteerRequestDto
   */
  volunteerId: number;
  /**
   *
   * @type {string}
   * @memberof CreateVolunteerRequestDto
   */
  city: string;
}
/**
 *
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  ssid: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface ExportWithCharityDto
 */
export interface ExportWithCharityDto {
  /**
   *
   * @type {string}
   * @memberof ExportWithCharityDto
   */
  exportId: string;
  /**
   *
   * @type {string}
   * @memberof ExportWithCharityDto
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof ExportWithCharityDto
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof ExportWithCharityDto
   */
  startDate: string;
  /**
   *
   * @type {boolean}
   * @memberof ExportWithCharityDto
   */
  includePdfs: boolean;
  /**
   *
   * @type {string}
   * @memberof ExportWithCharityDto
   */
  charityId: string;
  /**
   *
   * @type {string}
   * @memberof ExportWithCharityDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ExportWithCharityDto
   */
  updatedAt: string;
  /**
   *
   * @type {CharityDto}
   * @memberof ExportWithCharityDto
   */
  charity: CharityDto;
  /**
   *
   * @type {FileDto}
   * @memberof ExportWithCharityDto
   */
  file: FileDto;
}
/**
 *
 * @export
 * @interface FileDto
 */
export interface FileDto {
  /**
   *
   * @type {string}
   * @memberof FileDto
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof FileDto
   */
  downloadUrl: string;
  /**
   *
   * @type {string}
   * @memberof FileDto
   */
  path: string;
  /**
   *
   * @type {string}
   * @memberof FileDto
   */
  mimeType: string;
  /**
   *
   * @type {string}
   * @memberof FileDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof FileDto
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface FindSaleResponse
 */
export interface FindSaleResponse {
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  saleId: string;
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  notes: string;
  /**
   *
   * @type {number}
   * @memberof FindSaleResponse
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  bankAccount: string;
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  clearingNumber: string;
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  status: FindSaleResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  customerId: string;
  /**
   *
   * @type {number}
   * @memberof FindSaleResponse
   */
  volunteerId: number;
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  signatureDataUrl: string;
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  charityId: string;
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  updatedAt: string;
  /**
   *
   * @type {object}
   * @memberof FindSaleResponse
   */
  metadata: object;
  /**
   *
   * @type {CustomerDto}
   * @memberof FindSaleResponse
   */
  customer: CustomerDto;
  /**
   *
   * @type {CharityDto}
   * @memberof FindSaleResponse
   */
  charity: CharityDto;
  /**
   *
   * @type {string}
   * @memberof FindSaleResponse
   */
  publicKey: string;
}

export const FindSaleResponseStatusEnum = {
  Signed: 'SIGNED',
  Pending: 'PENDING',
} as const;

export type FindSaleResponseStatusEnum = (typeof FindSaleResponseStatusEnum)[keyof typeof FindSaleResponseStatusEnum];

/**
 *
 * @export
 * @interface GetAdyenPaymentStatusDto
 */
export interface GetAdyenPaymentStatusDto {
  /**
   *
   * @type {string}
   * @memberof GetAdyenPaymentStatusDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAdyenPaymentStatusDto
   */
  status?: GetAdyenPaymentStatusDtoStatusEnum;
}

export const GetAdyenPaymentStatusDtoStatusEnum = {
  Active: 'active',
  Canceled: 'canceled',
  Completed: 'completed',
  Expired: 'expired',
  PaymentPending: 'paymentPending',
  Refused: 'refused',
} as const;

export type GetAdyenPaymentStatusDtoStatusEnum =
  (typeof GetAdyenPaymentStatusDtoStatusEnum)[keyof typeof GetAdyenPaymentStatusDtoStatusEnum];

/**
 *
 * @export
 * @interface GetAllCharitiesResponse
 */
export interface GetAllCharitiesResponse {
  /**
   *
   * @type {Array<CharityWithControlQuestionsDto>}
   * @memberof GetAllCharitiesResponse
   */
  allCharities: Array<CharityWithControlQuestionsDto>;
}
/**
 *
 * @export
 * @interface GetAllVolunteersResponse
 */
export interface GetAllVolunteersResponse {
  /**
   *
   * @type {Array<VolunteerDto>}
   * @memberof GetAllVolunteersResponse
   */
  allVolunteers: Array<VolunteerDto>;
}
/**
 *
 * @export
 * @interface GetCharityPrefillResponse
 */
export interface GetCharityPrefillResponse {
  /**
   *
   * @type {string}
   * @memberof GetCharityPrefillResponse
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof GetCharityPrefillResponse
   */
  logoUrl: string;
  /**
   *
   * @type {string}
   * @memberof GetCharityPrefillResponse
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof GetCharityPrefillResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GetCharityPrefillResponse
   */
  bgColorHex: string;
  /**
   *
   * @type {string}
   * @memberof GetCharityPrefillResponse
   */
  flow: GetCharityPrefillResponseFlowEnum;
  /**
   *
   * @type {string}
   * @memberof GetCharityPrefillResponse
   */
  fontColorHex: string;
}

export const GetCharityPrefillResponseFlowEnum = {
  Regular: 'REGULAR',
  Plan: 'PLAN',
  Ssm: 'SSM',
  Rfsu: 'RFSU',
} as const;

export type GetCharityPrefillResponseFlowEnum =
  (typeof GetCharityPrefillResponseFlowEnum)[keyof typeof GetCharityPrefillResponseFlowEnum];

/**
 *
 * @export
 * @interface GetCharityStatisticsResponse
 */
export interface GetCharityStatisticsResponse {
  /**
   *
   * @type {Array<CityAndSales>}
   * @memberof GetCharityStatisticsResponse
   */
  cities: Array<CityAndSales>;
  /**
   *
   * @type {Array<VolunteerAndSales>}
   * @memberof GetCharityStatisticsResponse
   */
  volunteers: Array<VolunteerAndSales>;
}
/**
 *
 * @export
 * @interface GetCustomerResponse
 */
export interface GetCustomerResponse {
  /**
   *
   * @type {string}
   * @memberof GetCustomerResponse
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof GetCustomerResponse
   */
  ssid: string;
  /**
   *
   * @type {string}
   * @memberof GetCustomerResponse
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof GetCustomerResponse
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof GetCustomerResponse
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof GetCustomerResponse
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof GetCustomerResponse
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof GetCustomerResponse
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof GetCustomerResponse
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof GetCustomerResponse
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof GetCustomerResponse
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface GetExportsResponse
 */
export interface GetExportsResponse {
  /**
   *
   * @type {Array<ExportWithCharityDto>}
   * @memberof GetExportsResponse
   */
  exports: Array<ExportWithCharityDto>;
  /**
   *
   * @type {number}
   * @memberof GetExportsResponse
   */
  total: number;
}
/**
 *
 * @export
 * @interface GetIsAdminLoginValidResponse
 */
export interface GetIsAdminLoginValidResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetIsAdminLoginValidResponse
   */
  valid: boolean;
}
/**
 *
 * @export
 * @interface GetIsVolunteerValidResponse
 */
export interface GetIsVolunteerValidResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetIsVolunteerValidResponse
   */
  isValid: boolean;
}
/**
 *
 * @export
 * @interface GetMyCharityResponse
 */
export interface GetMyCharityResponse {
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  charityId: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  fontColorHex: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  bgColorHex: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  fileId: string;
  /**
   *
   * @type {FileDto}
   * @memberof GetMyCharityResponse
   */
  logo: FileDto;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  termsAndConditions: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  thankYouText: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  pageTwoText: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  externalFirebaseId: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  address: string;
  /**
   *
   * @type {number}
   * @memberof GetMyCharityResponse
   */
  maxSaleAmount: number;
  /**
   *
   * @type {number}
   * @memberof GetMyCharityResponse
   */
  minSaleAmount: number;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  deactivatedAt: string;
  /**
   *
   * @type {string}
   * @memberof GetMyCharityResponse
   */
  flow: GetMyCharityResponseFlowEnum;
  /**
   *
   * @type {Array<ControlQuestionDto>}
   * @memberof GetMyCharityResponse
   */
  controlQuestions: Array<ControlQuestionDto>;
}

export const GetMyCharityResponseFlowEnum = {
  Regular: 'REGULAR',
  Plan: 'PLAN',
  Ssm: 'SSM',
  Rfsu: 'RFSU',
} as const;

export type GetMyCharityResponseFlowEnum =
  (typeof GetMyCharityResponseFlowEnum)[keyof typeof GetMyCharityResponseFlowEnum];

/**
 *
 * @export
 * @interface GetPeriodVolunteerStatisticsResponse
 */
export interface GetPeriodVolunteerStatisticsResponse {
  /**
   *
   * @type {number}
   * @memberof GetPeriodVolunteerStatisticsResponse
   */
  salesPerDay: number;
  /**
   *
   * @type {number}
   * @memberof GetPeriodVolunteerStatisticsResponse
   */
  salesPerDayPercentageChange: number;
  /**
   *
   * @type {number}
   * @memberof GetPeriodVolunteerStatisticsResponse
   */
  totalForPeriod: number;
  /**
   *
   * @type {number}
   * @memberof GetPeriodVolunteerStatisticsResponse
   */
  totalForPeriodPercentageChange: number;
  /**
   *
   * @type {number}
   * @memberof GetPeriodVolunteerStatisticsResponse
   */
  salesThisYear: number;
  /**
   *
   * @type {number}
   * @memberof GetPeriodVolunteerStatisticsResponse
   */
  salesThisYearPercentageChange: number;
}
/**
 *
 * @export
 * @interface GetSalePublicKeyResponse
 */
export interface GetSalePublicKeyResponse {
  /**
   *
   * @type {string}
   * @memberof GetSalePublicKeyResponse
   */
  publicKey: string;
}
/**
 *
 * @export
 * @interface GetSaleThisBillingPeriodResponse
 */
export interface GetSaleThisBillingPeriodResponse {
  /**
   *
   * @type {string}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  saleId: string;
  /**
   *
   * @type {string}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  notes: string;
  /**
   *
   * @type {number}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  bankAccount: string;
  /**
   *
   * @type {string}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  clearingNumber: string;
  /**
   *
   * @type {string}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  status: GetSaleThisBillingPeriodResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  customerId: string;
  /**
   *
   * @type {number}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  volunteerId: number;
  /**
   *
   * @type {string}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  signatureDataUrl: string;
  /**
   *
   * @type {string}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  charityId: string;
  /**
   *
   * @type {string}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  updatedAt: string;
  /**
   *
   * @type {object}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  metadata: object;
  /**
   *
   * @type {CharityDto}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  charity: CharityDto;
  /**
   *
   * @type {VolunteerDto}
   * @memberof GetSaleThisBillingPeriodResponse
   */
  volunteer: VolunteerDto | null;
}

export const GetSaleThisBillingPeriodResponseStatusEnum = {
  Signed: 'SIGNED',
  Pending: 'PENDING',
} as const;

export type GetSaleThisBillingPeriodResponseStatusEnum =
  (typeof GetSaleThisBillingPeriodResponseStatusEnum)[keyof typeof GetSaleThisBillingPeriodResponseStatusEnum];

/**
 *
 * @export
 * @interface GetSalesStatisticsResponse
 */
export interface GetSalesStatisticsResponse {
  /**
   *
   * @type {number}
   * @memberof GetSalesStatisticsResponse
   */
  salesThisMonth: number;
  /**
   *
   * @type {number}
   * @memberof GetSalesStatisticsResponse
   */
  monthAverage: number;
  /**
   *
   * @type {number}
   * @memberof GetSalesStatisticsResponse
   */
  salesThisWeek: number;
  /**
   *
   * @type {number}
   * @memberof GetSalesStatisticsResponse
   */
  salesThisDay: number;
  /**
   *
   * @type {number}
   * @memberof GetSalesStatisticsResponse
   */
  saleAmountThisDay: number;
  /**
   *
   * @type {Array<CharityDto>}
   * @memberof GetSalesStatisticsResponse
   */
  allCharities: Array<CharityDto>;
  /**
   *
   * @type {Array<VolunteerDto>}
   * @memberof GetSalesStatisticsResponse
   */
  allVolunteers: Array<VolunteerDto>;
}
/**
 *
 * @export
 * @interface GetSparData
 */
export interface GetSparData {
  /**
   *
   * @type {string}
   * @memberof GetSparData
   */
  ssid: string;
  /**
   *
   * @type {string}
   * @memberof GetSparData
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof GetSparData
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof GetSparData
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof GetSparData
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof GetSparData
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof GetSparData
   */
  saleId: string;
  /**
   *
   * @type {string}
   * @memberof GetSparData
   */
  publicKey: string;
}
/**
 *
 * @export
 * @interface GetSparDataDto
 */
export interface GetSparDataDto {
  /**
   *
   * @type {number}
   * @memberof GetSparDataDto
   */
  volunteerId: number;
  /**
   *
   * @type {PlanMetadataDto}
   * @memberof GetSparDataDto
   */
  metadata?: PlanMetadataDto;
}
/**
 *
 * @export
 * @interface GetVolunteerStatisticsResponse
 */
export interface GetVolunteerStatisticsResponse {
  /**
   *
   * @type {number}
   * @memberof GetVolunteerStatisticsResponse
   */
  salesThisMonth: number;
  /**
   *
   * @type {number}
   * @memberof GetVolunteerStatisticsResponse
   */
  monthAverage: number;
  /**
   *
   * @type {number}
   * @memberof GetVolunteerStatisticsResponse
   */
  salesThisWeek: number;
}
/**
 *
 * @export
 * @interface ManualEntryDto
 */
export interface ManualEntryDto {
  /**
   *
   * @type {string}
   * @memberof ManualEntryDto
   */
  ssid: string;
  /**
   *
   * @type {string}
   * @memberof ManualEntryDto
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ManualEntryDto
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ManualEntryDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ManualEntryDto
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof ManualEntryDto
   */
  city: string;
  /**
   *
   * @type {number}
   * @memberof ManualEntryDto
   */
  volunteerId: number;
}
/**
 *
 * @export
 * @interface PaymentPickStatus
 */
export interface PaymentPickStatus {
  /**
   *
   * @type {string}
   * @memberof PaymentPickStatus
   */
  status: PaymentPickStatusStatusEnum;
}

export const PaymentPickStatusStatusEnum = {
  Error: 'ERROR',
  Pending: 'PENDING',
  Done: 'DONE',
} as const;

export type PaymentPickStatusStatusEnum =
  (typeof PaymentPickStatusStatusEnum)[keyof typeof PaymentPickStatusStatusEnum];

/**
 *
 * @export
 * @interface PlanMetadataDto
 */
export interface PlanMetadataDto {
  /**
   *
   * @type {string}
   * @memberof PlanMetadataDto
   */
  charityFlow: PlanMetadataDtoCharityFlowEnum;
  /**
   *
   * @type {string}
   * @memberof PlanMetadataDto
   */
  saleTechnique: PlanMetadataDtoSaleTechniqueEnum;
  /**
   *
   * @type {string}
   * @memberof PlanMetadataDto
   */
  sponsorType: PlanMetadataDtoSponsorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PlanMetadataDto
   */
  syncStatus: PlanMetadataDtoSyncStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PlanMetadataDto
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof PlanMetadataDto
   */
  paymentCheckoutUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PlanMetadataDto
   */
  recurringToken?: string;
  /**
   *
   * @type {string}
   * @memberof PlanMetadataDto
   */
  shopperReference?: string;
  /**
   *
   * @type {string}
   * @memberof PlanMetadataDto
   */
  pspReference?: string;
  /**
   *
   * @type {string}
   * @memberof PlanMetadataDto
   */
  paymentMethod?: string;
}

export const PlanMetadataDtoCharityFlowEnum = {
  Regular: 'REGULAR',
  Plan: 'PLAN',
  Ssm: 'SSM',
  Rfsu: 'RFSU',
} as const;

export type PlanMetadataDtoCharityFlowEnum =
  (typeof PlanMetadataDtoCharityFlowEnum)[keyof typeof PlanMetadataDtoCharityFlowEnum];
export const PlanMetadataDtoSaleTechniqueEnum = {
  D2D: 'D2D',
  F2F: 'F2F',
} as const;

export type PlanMetadataDtoSaleTechniqueEnum =
  (typeof PlanMetadataDtoSaleTechniqueEnum)[keyof typeof PlanMetadataDtoSaleTechniqueEnum];
export const PlanMetadataDtoSponsorTypeEnum = {
  GirlSponsor: 'GIRL_SPONSOR',
  DisasterSponsor: 'DISASTER_SPONSOR',
} as const;

export type PlanMetadataDtoSponsorTypeEnum =
  (typeof PlanMetadataDtoSponsorTypeEnum)[keyof typeof PlanMetadataDtoSponsorTypeEnum];
export const PlanMetadataDtoSyncStatusEnum = {
  Pending: 'pending',
  Done: 'done',
  Error: 'error',
} as const;

export type PlanMetadataDtoSyncStatusEnum =
  (typeof PlanMetadataDtoSyncStatusEnum)[keyof typeof PlanMetadataDtoSyncStatusEnum];

/**
 *
 * @export
 * @interface PublicSaleResponse
 */
export interface PublicSaleResponse {
  /**
   *
   * @type {string}
   * @memberof PublicSaleResponse
   */
  saleId: string;
  /**
   *
   * @type {string}
   * @memberof PublicSaleResponse
   */
  notes: string;
  /**
   *
   * @type {number}
   * @memberof PublicSaleResponse
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof PublicSaleResponse
   */
  bankAccount: string;
  /**
   *
   * @type {string}
   * @memberof PublicSaleResponse
   */
  clearingNumber: string;
  /**
   *
   * @type {string}
   * @memberof PublicSaleResponse
   */
  status: PublicSaleResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PublicSaleResponse
   */
  customerId: string;
  /**
   *
   * @type {number}
   * @memberof PublicSaleResponse
   */
  volunteerId: number;
  /**
   *
   * @type {string}
   * @memberof PublicSaleResponse
   */
  signatureDataUrl: string;
  /**
   *
   * @type {string}
   * @memberof PublicSaleResponse
   */
  charityId: string;
  /**
   *
   * @type {string}
   * @memberof PublicSaleResponse
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof PublicSaleResponse
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof PublicSaleResponse
   */
  updatedAt: string;
  /**
   *
   * @type {object}
   * @memberof PublicSaleResponse
   */
  metadata: object;
  /**
   *
   * @type {CustomerDto}
   * @memberof PublicSaleResponse
   */
  customer: CustomerDto;
  /**
   *
   * @type {CharityDto}
   * @memberof PublicSaleResponse
   */
  charity: CharityDto;
  /**
   *
   * @type {Array<PaymentPickStatus>}
   * @memberof PublicSaleResponse
   */
  payment: Array<PaymentPickStatus>;
}

export const PublicSaleResponseStatusEnum = {
  Signed: 'SIGNED',
  Pending: 'PENDING',
} as const;

export type PublicSaleResponseStatusEnum =
  (typeof PublicSaleResponseStatusEnum)[keyof typeof PublicSaleResponseStatusEnum];

/**
 *
 * @export
 * @interface SaleDto
 */
export interface SaleDto {
  /**
   *
   * @type {string}
   * @memberof SaleDto
   */
  saleId: string;
  /**
   *
   * @type {string}
   * @memberof SaleDto
   */
  notes: string;
  /**
   *
   * @type {number}
   * @memberof SaleDto
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof SaleDto
   */
  bankAccount: string;
  /**
   *
   * @type {string}
   * @memberof SaleDto
   */
  clearingNumber: string;
  /**
   *
   * @type {string}
   * @memberof SaleDto
   */
  status: SaleDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SaleDto
   */
  customerId: string;
  /**
   *
   * @type {number}
   * @memberof SaleDto
   */
  volunteerId: number;
  /**
   *
   * @type {string}
   * @memberof SaleDto
   */
  signatureDataUrl: string;
  /**
   *
   * @type {string}
   * @memberof SaleDto
   */
  charityId: string;
  /**
   *
   * @type {string}
   * @memberof SaleDto
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof SaleDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof SaleDto
   */
  updatedAt: string;
  /**
   *
   * @type {object}
   * @memberof SaleDto
   */
  metadata: object;
}

export const SaleDtoStatusEnum = {
  Signed: 'SIGNED',
  Pending: 'PENDING',
} as const;

export type SaleDtoStatusEnum = (typeof SaleDtoStatusEnum)[keyof typeof SaleDtoStatusEnum];

/**
 *
 * @export
 * @interface SalesGroupedByCharity
 */
export interface SalesGroupedByCharity {
  /**
   *
   * @type {Array<CharityAndAmount>}
   * @memberof SalesGroupedByCharity
   */
  charitiesAndAmount: Array<CharityAndAmount>;
}
/**
 *
 * @export
 * @interface SalesGroupedByVolunteer
 */
export interface SalesGroupedByVolunteer {
  /**
   *
   * @type {Array<VolunteerAndAmount>}
   * @memberof SalesGroupedByVolunteer
   */
  volunteersAndAmount: Array<VolunteerAndAmount>;
}
/**
 *
 * @export
 * @interface SignSaleDto
 */
export interface SignSaleDto {
  /**
   *
   * @type {string}
   * @memberof SignSaleDto
   */
  signatureDataUrl: string;
  /**
   *
   * @type {string}
   * @memberof SignSaleDto
   */
  publicKey: string;
}
/**
 *
 * @export
 * @interface UpdateCharityRequestDto
 */
export interface UpdateCharityRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  fontColorHex: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  bgColorHex: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  termsAndConditions: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  thankYouText: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  pageTwoText: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  maxSaleAmount: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  minSaleAmount: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateCharityRequestDto
   */
  controlQuestions: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UpdateCharityRequestDto
   */
  flow: UpdateCharityRequestDtoFlowEnum;
}

export const UpdateCharityRequestDtoFlowEnum = {
  Regular: 'REGULAR',
  Plan: 'PLAN',
  Ssm: 'SSM',
  Rfsu: 'RFSU',
} as const;

export type UpdateCharityRequestDtoFlowEnum =
  (typeof UpdateCharityRequestDtoFlowEnum)[keyof typeof UpdateCharityRequestDtoFlowEnum];

/**
 *
 * @export
 * @interface UpdateCustomerDto
 */
export interface UpdateCustomerDto {
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerDto
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerDto
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerDto
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerDto
   */
  zipCode?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerDto
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerDto
   */
  phone?: string;
}
/**
 *
 * @export
 * @interface UpdateSaleDto
 */
export interface UpdateSaleDto {
  /**
   *
   * @type {number}
   * @memberof UpdateSaleDto
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateSaleDto
   */
  bankAccount?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSaleDto
   */
  clearingNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSaleDto
   */
  notes?: string;
  /**
   *
   * @type {PlanMetadataDto}
   * @memberof UpdateSaleDto
   */
  metadata?: PlanMetadataDto;
}
/**
 *
 * @export
 * @interface VolunteerAndAmount
 */
export interface VolunteerAndAmount {
  /**
   *
   * @type {string}
   * @memberof VolunteerAndAmount
   */
  volunteerName: string;
  /**
   *
   * @type {number}
   * @memberof VolunteerAndAmount
   */
  amount: number;
}
/**
 *
 * @export
 * @interface VolunteerAndSales
 */
export interface VolunteerAndSales {
  /**
   *
   * @type {string}
   * @memberof VolunteerAndSales
   */
  volunteer: string;
  /**
   *
   * @type {number}
   * @memberof VolunteerAndSales
   */
  amountOfSales: number;
}
/**
 *
 * @export
 * @interface VolunteerDto
 */
export interface VolunteerDto {
  /**
   *
   * @type {string}
   * @memberof VolunteerDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof VolunteerDto
   */
  volunteerId: number;
  /**
   *
   * @type {string}
   * @memberof VolunteerDto
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof VolunteerDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof VolunteerDto
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof VolunteerDto
   */
  deactivatedAt: string;
}
/**
 *
 * @export
 * @interface WebhookDto
 */
export interface WebhookDto {
  /**
   *
   * @type {Array<object>}
   * @memberof WebhookDto
   */
  notificationItems: Array<object>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Used for fetching all the charities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAllCharities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/charities/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for fetching all the volunteers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAllVolunteers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/volunteers/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for creating a new charity
     * @param {CreateCharityRequestDto} createCharityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCreateCharity: async (
      createCharityRequestDto: CreateCharityRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createCharityRequestDto' is not null or undefined
      assertParamExists('adminControllerCreateCharity', 'createCharityRequestDto', createCharityRequestDto);
      const localVarPath = `/api/v1/admin/charity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCharityRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateExportRequestDto} createExportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCreateExport: async (
      createExportRequestDto: CreateExportRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createExportRequestDto' is not null or undefined
      assertParamExists('adminControllerCreateExport', 'createExportRequestDto', createExportRequestDto);
      const localVarPath = `/api/v1/admin/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createExportRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for creating a new volunteer
     * @param {CreateVolunteerRequestDto} createVolunteerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCreateVolunteer: async (
      createVolunteerRequestDto: CreateVolunteerRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createVolunteerRequestDto' is not null or undefined
      assertParamExists('adminControllerCreateVolunteer', 'createVolunteerRequestDto', createVolunteerRequestDto);
      const localVarPath = `/api/v1/admin/volunteer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createVolunteerRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deactivates specified charity. Connected sales will also be deleted.
     * @param {string} charityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeactivateCharity: async (
      charityId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'charityId' is not null or undefined
      assertParamExists('adminControllerDeactivateCharity', 'charityId', charityId);
      const localVarPath = `/api/v1/admin/charity/{charityId}`.replace(
        `{${'charityId'}}`,
        encodeURIComponent(String(charityId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deactivates specified volunteer. Connected sales will be kept.
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeactivateVolunteer: async (
      volunteerId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'volunteerId' is not null or undefined
      assertParamExists('adminControllerDeactivateVolunteer', 'volunteerId', volunteerId);
      const localVarPath = `/api/v1/admin/volunteer/{volunteerId}`.replace(
        `{${'volunteerId'}}`,
        encodeURIComponent(String(volunteerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for fetching all exports
     * @param {string} [take]
     * @param {string} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetAllExports: async (
      take?: string,
      skip?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for verifying the firebase user being admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerIsLoginValid: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/validLogin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for getting the public key for a specified sale
     * @param {string} saleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSalePublicKey: async (saleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'saleId' is not null or undefined
      assertParamExists('adminControllerSalePublicKey', 'saleId', saleId);
      const localVarPath = `/api/v1/admin/sale/{saleId}/public-key`.replace(
        `{${'saleId'}}`,
        encodeURIComponent(String(saleId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for fetching all the sales grouped by charity
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSalesGroupedByCharities: async (
      from: string,
      to: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists('adminControllerSalesGroupedByCharities', 'from', from);
      // verify required parameter 'to' is not null or undefined
      assertParamExists('adminControllerSalesGroupedByCharities', 'to', to);
      const localVarPath = `/api/v1/admin/sales/charities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for fetching the admin sales statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSalesStatistics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/statistics/sales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for fetching all sales this billing period
     * @param {any} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSalesThisBillingPeriod: async (
      filter?: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/sales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for fetching specified volunteer sales statistics
     * @param {string} from
     * @param {string} to
     * @param {string} charityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerScopedCharitySalesStatistics: async (
      from: string,
      to: string,
      charityId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists('adminControllerScopedCharitySalesStatistics', 'from', from);
      // verify required parameter 'to' is not null or undefined
      assertParamExists('adminControllerScopedCharitySalesStatistics', 'to', to);
      // verify required parameter 'charityId' is not null or undefined
      assertParamExists('adminControllerScopedCharitySalesStatistics', 'charityId', charityId);
      const localVarPath = `/api/v1/admin/scoped-statistics/charity/{charityId}`.replace(
        `{${'charityId'}}`,
        encodeURIComponent(String(charityId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for fetching specified volunteer sales statistics
     * @param {string} from
     * @param {string} to
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerScopedVolunteerSalesStatistics: async (
      from: string,
      to: string,
      volunteerId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists('adminControllerScopedVolunteerSalesStatistics', 'from', from);
      // verify required parameter 'to' is not null or undefined
      assertParamExists('adminControllerScopedVolunteerSalesStatistics', 'to', to);
      // verify required parameter 'volunteerId' is not null or undefined
      assertParamExists('adminControllerScopedVolunteerSalesStatistics', 'volunteerId', volunteerId);
      const localVarPath = `/api/v1/admin/scoped-statistics/volunteer/{volunteerId}`.replace(
        `{${'volunteerId'}}`,
        encodeURIComponent(String(volunteerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for updating a charity
     * @param {string} charityId
     * @param {UpdateCharityRequestDto} updateCharityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerUpdateCharity: async (
      charityId: string,
      updateCharityRequestDto: UpdateCharityRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'charityId' is not null or undefined
      assertParamExists('adminControllerUpdateCharity', 'charityId', charityId);
      // verify required parameter 'updateCharityRequestDto' is not null or undefined
      assertParamExists('adminControllerUpdateCharity', 'updateCharityRequestDto', updateCharityRequestDto);
      const localVarPath = `/api/v1/admin/charity/{charityId}`.replace(
        `{${'charityId'}}`,
        encodeURIComponent(String(charityId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCharityRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerUploadFile: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for fetching specified volunteer sales grouped by charity
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerVolunteerSalesGroupedByCharity: async (
      from: string,
      to: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists('adminControllerVolunteerSalesGroupedByCharity', 'from', from);
      // verify required parameter 'to' is not null or undefined
      assertParamExists('adminControllerVolunteerSalesGroupedByCharity', 'to', to);
      const localVarPath = `/api/v1/admin/sales/volunteers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for retrieving pings from the Adyen API
     * @param {WebhookDto} webhookDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adyenControllerWebhook: async (webhookDto: WebhookDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'webhookDto' is not null or undefined
      assertParamExists('adyenControllerWebhook', 'webhookDto', webhookDto);
      const localVarPath = `/api/v1/adyen/webhook`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(webhookDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for getting a charity prefill
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    charityControllerGetCharityPrefill: async (
      slug: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'slug' is not null or undefined
      assertParamExists('charityControllerGetCharityPrefill', 'slug', slug);
      const localVarPath = `/api/v1/charity/{slug}/prefill`.replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for getting a charity\'s data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    charityControllerGetMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/charity/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for getting a charity publically
     * @param {string} slug
     * @param {string} salePublicKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    charityControllerGetPublicCharity: async (
      slug: string,
      salePublicKey: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'slug' is not null or undefined
      assertParamExists('charityControllerGetPublicCharity', 'slug', slug);
      // verify required parameter 'salePublicKey' is not null or undefined
      assertParamExists('charityControllerGetPublicCharity', 'salePublicKey', salePublicKey);
      const localVarPath = `/api/v1/charity/public/{slug}/{salePublicKey}`
        .replace(`{${'slug'}}`, encodeURIComponent(String(slug)))
        .replace(`{${'salePublicKey'}}`, encodeURIComponent(String(salePublicKey)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for getting a charity\'s statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    charityControllerGetStatistics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/charity/statistics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for validating a volunteer id
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    charityControllerValidVolunteerId: async (
      volunteerId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'volunteerId' is not null or undefined
      assertParamExists('charityControllerValidVolunteerId', 'volunteerId', volunteerId);
      const localVarPath = `/api/v1/charity/{slug}/valid-volunteer/{volunteerId}`.replace(
        `{${'volunteerId'}}`,
        encodeURIComponent(String(volunteerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for periodically cleaning the database of old exports and sales.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cronJobControllerPeriodCleanup: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/cron-job/period-cleanup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for creating a Customer
     * @param {CreateCustomerDto} createCustomerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerControllerCreateCustomer: async (
      createCustomerDto: CreateCustomerDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createCustomerDto' is not null or undefined
      assertParamExists('customerControllerCreateCustomer', 'createCustomerDto', createCustomerDto);
      const localVarPath = `/api/v1/customer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createCustomerDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for getting a customer\'s data using its ssid
     * @param {string} ssid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerControllerFindOne: async (ssid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'ssid' is not null or undefined
      assertParamExists('customerControllerFindOne', 'ssid', ssid);
      const localVarPath = `/api/v1/customer/{ssid}`.replace(`{${'ssid'}}`, encodeURIComponent(String(ssid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for updating a Customer
     * @param {string} ssid
     * @param {UpdateCustomerDto} updateCustomerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerControllerUpdateCustomer: async (
      ssid: string,
      updateCustomerDto: UpdateCustomerDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ssid' is not null or undefined
      assertParamExists('customerControllerUpdateCustomer', 'ssid', ssid);
      // verify required parameter 'updateCustomerDto' is not null or undefined
      assertParamExists('customerControllerUpdateCustomer', 'updateCustomerDto', updateCustomerDto);
      const localVarPath = `/api/v1/customer/{ssid}`.replace(`{${'ssid'}}`, encodeURIComponent(String(ssid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for creating a new payment
     * @param {CreatePaymentDto} createPaymentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerCreatePayment: async (
      createPaymentDto: CreatePaymentDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPaymentDto' is not null or undefined
      assertParamExists('paymentControllerCreatePayment', 'createPaymentDto', createPaymentDto);
      const localVarPath = `/api/v1/payment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createPaymentDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for getting the status of an Adyen payment
     * @param {string} sessionId
     * @param {string} sessionResult
     * @param {string} publicKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerGetAndSetPlanAdyenPaymentStatus: async (
      sessionId: string,
      sessionResult: string,
      publicKey: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists('paymentControllerGetAndSetPlanAdyenPaymentStatus', 'sessionId', sessionId);
      // verify required parameter 'sessionResult' is not null or undefined
      assertParamExists('paymentControllerGetAndSetPlanAdyenPaymentStatus', 'sessionResult', sessionResult);
      // verify required parameter 'publicKey' is not null or undefined
      assertParamExists('paymentControllerGetAndSetPlanAdyenPaymentStatus', 'publicKey', publicKey);
      const localVarPath = `/api/v1/payment/payment-status/{sessionId}/{sessionResult}/{publicKey}`
        .replace(`{${'sessionId'}}`, encodeURIComponent(String(sessionId)))
        .replace(`{${'sessionResult'}}`, encodeURIComponent(String(sessionResult)))
        .replace(`{${'publicKey'}}`, encodeURIComponent(String(publicKey)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for creating a new sale
     * @param {CreateSaleDto} createSaleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saleControllerCreateSale: async (
      createSaleDto: CreateSaleDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSaleDto' is not null or undefined
      assertParamExists('saleControllerCreateSale', 'createSaleDto', createSaleDto);
      const localVarPath = `/api/v1/sale`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createSaleDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for fetching a sale
     * @param {string} saleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saleControllerFindOne: async (saleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'saleId' is not null or undefined
      assertParamExists('saleControllerFindOne', 'saleId', saleId);
      const localVarPath = `/api/v1/sale/{saleId}`.replace(`{${'saleId'}}`, encodeURIComponent(String(saleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for publically finding a sale
     * @param {string} saleId
     * @param {string} publicKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saleControllerFindPublicSale: async (
      saleId: string,
      publicKey: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'saleId' is not null or undefined
      assertParamExists('saleControllerFindPublicSale', 'saleId', saleId);
      // verify required parameter 'publicKey' is not null or undefined
      assertParamExists('saleControllerFindPublicSale', 'publicKey', publicKey);
      const localVarPath = `/api/v1/sale/public/{saleId}/{publicKey}`
        .replace(`{${'saleId'}}`, encodeURIComponent(String(saleId)))
        .replace(`{${'publicKey'}}`, encodeURIComponent(String(publicKey)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for signing a sale
     * @param {string} saleId
     * @param {SignSaleDto} signSaleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saleControllerSignSale: async (
      saleId: string,
      signSaleDto: SignSaleDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'saleId' is not null or undefined
      assertParamExists('saleControllerSignSale', 'saleId', saleId);
      // verify required parameter 'signSaleDto' is not null or undefined
      assertParamExists('saleControllerSignSale', 'signSaleDto', signSaleDto);
      const localVarPath = `/api/v1/sale/{saleId}/sign`.replace(`{${'saleId'}}`, encodeURIComponent(String(saleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(signSaleDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for updating a sale
     * @param {string} saleId
     * @param {UpdateSaleDto} updateSaleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saleControllerUpdateSale: async (
      saleId: string,
      updateSaleDto: UpdateSaleDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'saleId' is not null or undefined
      assertParamExists('saleControllerUpdateSale', 'saleId', saleId);
      // verify required parameter 'updateSaleDto' is not null or undefined
      assertParamExists('saleControllerUpdateSale', 'updateSaleDto', updateSaleDto);
      const localVarPath = `/api/v1/sale/{saleId}`.replace(`{${'saleId'}}`, encodeURIComponent(String(saleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateSaleDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for creating spar data and creating the needed associated entities.
     * @param {ManualEntryDto} manualEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sparControllerCreate: async (
      manualEntryDto: ManualEntryDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'manualEntryDto' is not null or undefined
      assertParamExists('sparControllerCreate', 'manualEntryDto', manualEntryDto);
      const localVarPath = `/api/v1/spar`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(manualEntryDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for getting spar data using ssid and creating the needed associated entities.
     * @param {string} ssid
     * @param {GetSparDataDto} getSparDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sparControllerFindOne: async (
      ssid: string,
      getSparDataDto: GetSparDataDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ssid' is not null or undefined
      assertParamExists('sparControllerFindOne', 'ssid', ssid);
      // verify required parameter 'getSparDataDto' is not null or undefined
      assertParamExists('sparControllerFindOne', 'getSparDataDto', getSparDataDto);
      const localVarPath = `/api/v1/spar/{ssid}`.replace(`{${'ssid'}}`, encodeURIComponent(String(ssid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(getSparDataDto, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used for getting a volunteer\'s statistics
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    volunteerControllerGetMyStatistics: async (
      volunteerId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'volunteerId' is not null or undefined
      assertParamExists('volunteerControllerGetMyStatistics', 'volunteerId', volunteerId);
      const localVarPath = `/api/v1/volunteer/{volunteerId}/statistics`.replace(
        `{${'volunteerId'}}`,
        encodeURIComponent(String(volunteerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workerControllerExecute: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/worker`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Used for fetching all the charities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerAllCharities(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllCharitiesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerAllCharities(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for fetching all the volunteers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerAllVolunteers(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllVolunteersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerAllVolunteers(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for creating a new charity
     * @param {CreateCharityRequestDto} createCharityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCreateCharity(
      createCharityRequestDto: CreateCharityRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerCreateCharity(
        createCharityRequestDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateExportRequestDto} createExportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCreateExport(
      createExportRequestDto: CreateExportRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerCreateExport(
        createExportRequestDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for creating a new volunteer
     * @param {CreateVolunteerRequestDto} createVolunteerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCreateVolunteer(
      createVolunteerRequestDto: CreateVolunteerRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerCreateVolunteer(
        createVolunteerRequestDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Deactivates specified charity. Connected sales will also be deleted.
     * @param {string} charityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerDeactivateCharity(
      charityId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerDeactivateCharity(charityId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Deactivates specified volunteer. Connected sales will be kept.
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerDeactivateVolunteer(
      volunteerId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerDeactivateVolunteer(
        volunteerId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for fetching all exports
     * @param {string} [take]
     * @param {string} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerGetAllExports(
      take?: string,
      skip?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExportsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerGetAllExports(take, skip, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for verifying the firebase user being admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerIsLoginValid(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIsAdminLoginValidResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerIsLoginValid(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for getting the public key for a specified sale
     * @param {string} saleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerSalePublicKey(
      saleId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSalePublicKeyResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerSalePublicKey(saleId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for fetching all the sales grouped by charity
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerSalesGroupedByCharities(
      from: string,
      to: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesGroupedByCharity>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerSalesGroupedByCharities(
        from,
        to,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for fetching the admin sales statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerSalesStatistics(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSalesStatisticsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerSalesStatistics(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for fetching all sales this billing period
     * @param {any} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerSalesThisBillingPeriod(
      filter?: any,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetSaleThisBillingPeriodResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerSalesThisBillingPeriod(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for fetching specified volunteer sales statistics
     * @param {string} from
     * @param {string} to
     * @param {string} charityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerScopedCharitySalesStatistics(
      from: string,
      to: string,
      charityId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPeriodVolunteerStatisticsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerScopedCharitySalesStatistics(
        from,
        to,
        charityId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for fetching specified volunteer sales statistics
     * @param {string} from
     * @param {string} to
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerScopedVolunteerSalesStatistics(
      from: string,
      to: string,
      volunteerId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPeriodVolunteerStatisticsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerScopedVolunteerSalesStatistics(
        from,
        to,
        volunteerId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for updating a charity
     * @param {string} charityId
     * @param {UpdateCharityRequestDto} updateCharityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerUpdateCharity(
      charityId: string,
      updateCharityRequestDto: UpdateCharityRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerUpdateCharity(
        charityId,
        updateCharityRequestDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerUploadFile(
      file?: File,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUploadFileResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerUploadFile(file, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for fetching specified volunteer sales grouped by charity
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerVolunteerSalesGroupedByCharity(
      from: string,
      to: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesGroupedByVolunteer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerVolunteerSalesGroupedByCharity(
        from,
        to,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for retrieving pings from the Adyen API
     * @param {WebhookDto} webhookDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adyenControllerWebhook(
      webhookDto: WebhookDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adyenControllerWebhook(webhookDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for getting a charity prefill
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async charityControllerGetCharityPrefill(
      slug: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCharityPrefillResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.charityControllerGetCharityPrefill(slug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for getting a charity\'s data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async charityControllerGetMe(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyCharityResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.charityControllerGetMe(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for getting a charity publically
     * @param {string} slug
     * @param {string} salePublicKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async charityControllerGetPublicCharity(
      slug: string,
      salePublicKey: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyCharityResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.charityControllerGetPublicCharity(
        slug,
        salePublicKey,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for getting a charity\'s statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async charityControllerGetStatistics(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCharityStatisticsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.charityControllerGetStatistics(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for validating a volunteer id
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async charityControllerValidVolunteerId(
      volunteerId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIsVolunteerValidResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.charityControllerValidVolunteerId(volunteerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for periodically cleaning the database of old exports and sales.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cronJobControllerPeriodCleanup(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cronJobControllerPeriodCleanup(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for creating a Customer
     * @param {CreateCustomerDto} createCustomerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customerControllerCreateCustomer(
      createCustomerDto: CreateCustomerDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerCreateCustomer(
        createCustomerDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for getting a customer\'s data using its ssid
     * @param {string} ssid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customerControllerFindOne(
      ssid: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerFindOne(ssid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for updating a Customer
     * @param {string} ssid
     * @param {UpdateCustomerDto} updateCustomerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customerControllerUpdateCustomer(
      ssid: string,
      updateCustomerDto: UpdateCustomerDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerUpdateCustomer(
        ssid,
        updateCustomerDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for creating a new payment
     * @param {CreatePaymentDto} createPaymentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentControllerCreatePayment(
      createPaymentDto: CreatePaymentDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePaymentResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerCreatePayment(
        createPaymentDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for getting the status of an Adyen payment
     * @param {string} sessionId
     * @param {string} sessionResult
     * @param {string} publicKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentControllerGetAndSetPlanAdyenPaymentStatus(
      sessionId: string,
      sessionResult: string,
      publicKey: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdyenPaymentStatusDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerGetAndSetPlanAdyenPaymentStatus(
        sessionId,
        sessionResult,
        publicKey,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for creating a new sale
     * @param {CreateSaleDto} createSaleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saleControllerCreateSale(
      createSaleDto: CreateSaleDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saleControllerCreateSale(createSaleDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for fetching a sale
     * @param {string} saleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saleControllerFindOne(
      saleId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindSaleResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saleControllerFindOne(saleId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for publically finding a sale
     * @param {string} saleId
     * @param {string} publicKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saleControllerFindPublicSale(
      saleId: string,
      publicKey: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicSaleResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saleControllerFindPublicSale(
        saleId,
        publicKey,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for signing a sale
     * @param {string} saleId
     * @param {SignSaleDto} signSaleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saleControllerSignSale(
      saleId: string,
      signSaleDto: SignSaleDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saleControllerSignSale(saleId, signSaleDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for updating a sale
     * @param {string} saleId
     * @param {UpdateSaleDto} updateSaleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saleControllerUpdateSale(
      saleId: string,
      updateSaleDto: UpdateSaleDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saleControllerUpdateSale(
        saleId,
        updateSaleDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for creating spar data and creating the needed associated entities.
     * @param {ManualEntryDto} manualEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sparControllerCreate(
      manualEntryDto: ManualEntryDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSparData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sparControllerCreate(manualEntryDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for getting spar data using ssid and creating the needed associated entities.
     * @param {string} ssid
     * @param {GetSparDataDto} getSparDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sparControllerFindOne(
      ssid: string,
      getSparDataDto: GetSparDataDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSparData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sparControllerFindOne(ssid, getSparDataDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used for getting a volunteer\'s statistics
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async volunteerControllerGetMyStatistics(
      volunteerId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVolunteerStatisticsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.volunteerControllerGetMyStatistics(
        volunteerId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workerControllerExecute(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workerControllerExecute(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Used for fetching all the charities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAllCharities(options?: any): AxiosPromise<GetAllCharitiesResponse> {
      return localVarFp.adminControllerAllCharities(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for fetching all the volunteers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAllVolunteers(options?: any): AxiosPromise<GetAllVolunteersResponse> {
      return localVarFp.adminControllerAllVolunteers(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for creating a new charity
     * @param {CreateCharityRequestDto} createCharityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCreateCharity(createCharityRequestDto: CreateCharityRequestDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .adminControllerCreateCharity(createCharityRequestDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CreateExportRequestDto} createExportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCreateExport(createExportRequestDto: CreateExportRequestDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .adminControllerCreateExport(createExportRequestDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for creating a new volunteer
     * @param {CreateVolunteerRequestDto} createVolunteerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCreateVolunteer(
      createVolunteerRequestDto: CreateVolunteerRequestDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .adminControllerCreateVolunteer(createVolunteerRequestDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Deactivates specified charity. Connected sales will also be deleted.
     * @param {string} charityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeactivateCharity(charityId: string, options?: any): AxiosPromise<void> {
      return localVarFp.adminControllerDeactivateCharity(charityId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Deactivates specified volunteer. Connected sales will be kept.
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeactivateVolunteer(volunteerId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .adminControllerDeactivateVolunteer(volunteerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for fetching all exports
     * @param {string} [take]
     * @param {string} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetAllExports(take?: string, skip?: string, options?: any): AxiosPromise<GetExportsResponse> {
      return localVarFp.adminControllerGetAllExports(take, skip, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for verifying the firebase user being admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerIsLoginValid(options?: any): AxiosPromise<GetIsAdminLoginValidResponse> {
      return localVarFp.adminControllerIsLoginValid(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for getting the public key for a specified sale
     * @param {string} saleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSalePublicKey(saleId: string, options?: any): AxiosPromise<GetSalePublicKeyResponse> {
      return localVarFp.adminControllerSalePublicKey(saleId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for fetching all the sales grouped by charity
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSalesGroupedByCharities(
      from: string,
      to: string,
      options?: any,
    ): AxiosPromise<SalesGroupedByCharity> {
      return localVarFp
        .adminControllerSalesGroupedByCharities(from, to, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for fetching the admin sales statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSalesStatistics(options?: any): AxiosPromise<GetSalesStatisticsResponse> {
      return localVarFp.adminControllerSalesStatistics(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for fetching all sales this billing period
     * @param {any} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSalesThisBillingPeriod(
      filter?: any,
      options?: any,
    ): AxiosPromise<Array<GetSaleThisBillingPeriodResponse>> {
      return localVarFp
        .adminControllerSalesThisBillingPeriod(filter, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for fetching specified volunteer sales statistics
     * @param {string} from
     * @param {string} to
     * @param {string} charityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerScopedCharitySalesStatistics(
      from: string,
      to: string,
      charityId: string,
      options?: any,
    ): AxiosPromise<GetPeriodVolunteerStatisticsResponse> {
      return localVarFp
        .adminControllerScopedCharitySalesStatistics(from, to, charityId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for fetching specified volunteer sales statistics
     * @param {string} from
     * @param {string} to
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerScopedVolunteerSalesStatistics(
      from: string,
      to: string,
      volunteerId: string,
      options?: any,
    ): AxiosPromise<GetPeriodVolunteerStatisticsResponse> {
      return localVarFp
        .adminControllerScopedVolunteerSalesStatistics(from, to, volunteerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for updating a charity
     * @param {string} charityId
     * @param {UpdateCharityRequestDto} updateCharityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerUpdateCharity(
      charityId: string,
      updateCharityRequestDto: UpdateCharityRequestDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .adminControllerUpdateCharity(charityId, updateCharityRequestDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerUploadFile(file?: File, options?: any): AxiosPromise<AdminUploadFileResponseDto> {
      return localVarFp.adminControllerUploadFile(file, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for fetching specified volunteer sales grouped by charity
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerVolunteerSalesGroupedByCharity(
      from: string,
      to: string,
      options?: any,
    ): AxiosPromise<SalesGroupedByVolunteer> {
      return localVarFp
        .adminControllerVolunteerSalesGroupedByCharity(from, to, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for retrieving pings from the Adyen API
     * @param {WebhookDto} webhookDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adyenControllerWebhook(webhookDto: WebhookDto, options?: any): AxiosPromise<void> {
      return localVarFp.adyenControllerWebhook(webhookDto, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for getting a charity prefill
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    charityControllerGetCharityPrefill(slug: string, options?: any): AxiosPromise<GetCharityPrefillResponse> {
      return localVarFp.charityControllerGetCharityPrefill(slug, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for getting a charity\'s data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    charityControllerGetMe(options?: any): AxiosPromise<GetMyCharityResponse> {
      return localVarFp.charityControllerGetMe(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for getting a charity publically
     * @param {string} slug
     * @param {string} salePublicKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    charityControllerGetPublicCharity(
      slug: string,
      salePublicKey: string,
      options?: any,
    ): AxiosPromise<GetMyCharityResponse> {
      return localVarFp
        .charityControllerGetPublicCharity(slug, salePublicKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for getting a charity\'s statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    charityControllerGetStatistics(options?: any): AxiosPromise<GetCharityStatisticsResponse> {
      return localVarFp.charityControllerGetStatistics(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for validating a volunteer id
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    charityControllerValidVolunteerId(volunteerId: string, options?: any): AxiosPromise<GetIsVolunteerValidResponse> {
      return localVarFp
        .charityControllerValidVolunteerId(volunteerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for periodically cleaning the database of old exports and sales.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cronJobControllerPeriodCleanup(options?: any): AxiosPromise<void> {
      return localVarFp.cronJobControllerPeriodCleanup(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for creating a Customer
     * @param {CreateCustomerDto} createCustomerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerControllerCreateCustomer(createCustomerDto: CreateCustomerDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .customerControllerCreateCustomer(createCustomerDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for getting a customer\'s data using its ssid
     * @param {string} ssid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerControllerFindOne(ssid: string, options?: any): AxiosPromise<GetCustomerResponse> {
      return localVarFp.customerControllerFindOne(ssid, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for updating a Customer
     * @param {string} ssid
     * @param {UpdateCustomerDto} updateCustomerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerControllerUpdateCustomer(
      ssid: string,
      updateCustomerDto: UpdateCustomerDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .customerControllerUpdateCustomer(ssid, updateCustomerDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for creating a new payment
     * @param {CreatePaymentDto} createPaymentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerCreatePayment(
      createPaymentDto: CreatePaymentDto,
      options?: any,
    ): AxiosPromise<CreatePaymentResponseDto> {
      return localVarFp
        .paymentControllerCreatePayment(createPaymentDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for getting the status of an Adyen payment
     * @param {string} sessionId
     * @param {string} sessionResult
     * @param {string} publicKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerGetAndSetPlanAdyenPaymentStatus(
      sessionId: string,
      sessionResult: string,
      publicKey: string,
      options?: any,
    ): AxiosPromise<GetAdyenPaymentStatusDto> {
      return localVarFp
        .paymentControllerGetAndSetPlanAdyenPaymentStatus(sessionId, sessionResult, publicKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for creating a new sale
     * @param {CreateSaleDto} createSaleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saleControllerCreateSale(createSaleDto: CreateSaleDto, options?: any): AxiosPromise<SaleDto> {
      return localVarFp.saleControllerCreateSale(createSaleDto, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for fetching a sale
     * @param {string} saleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saleControllerFindOne(saleId: string, options?: any): AxiosPromise<FindSaleResponse> {
      return localVarFp.saleControllerFindOne(saleId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for publically finding a sale
     * @param {string} saleId
     * @param {string} publicKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saleControllerFindPublicSale(saleId: string, publicKey: string, options?: any): AxiosPromise<PublicSaleResponse> {
      return localVarFp
        .saleControllerFindPublicSale(saleId, publicKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for signing a sale
     * @param {string} saleId
     * @param {SignSaleDto} signSaleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saleControllerSignSale(saleId: string, signSaleDto: SignSaleDto, options?: any): AxiosPromise<void> {
      return localVarFp.saleControllerSignSale(saleId, signSaleDto, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for updating a sale
     * @param {string} saleId
     * @param {UpdateSaleDto} updateSaleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saleControllerUpdateSale(saleId: string, updateSaleDto: UpdateSaleDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .saleControllerUpdateSale(saleId, updateSaleDto, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for creating spar data and creating the needed associated entities.
     * @param {ManualEntryDto} manualEntryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sparControllerCreate(manualEntryDto: ManualEntryDto, options?: any): AxiosPromise<GetSparData> {
      return localVarFp.sparControllerCreate(manualEntryDto, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for getting spar data using ssid and creating the needed associated entities.
     * @param {string} ssid
     * @param {GetSparDataDto} getSparDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sparControllerFindOne(ssid: string, getSparDataDto: GetSparDataDto, options?: any): AxiosPromise<GetSparData> {
      return localVarFp.sparControllerFindOne(ssid, getSparDataDto, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Used for getting a volunteer\'s statistics
     * @param {string} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    volunteerControllerGetMyStatistics(
      volunteerId: string,
      options?: any,
    ): AxiosPromise<GetVolunteerStatisticsResponse> {
      return localVarFp
        .volunteerControllerGetMyStatistics(volunteerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workerControllerExecute(options?: any): AxiosPromise<void> {
      return localVarFp.workerControllerExecute(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Used for fetching all the charities
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerAllCharities(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerAllCharities(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for fetching all the volunteers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerAllVolunteers(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerAllVolunteers(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for creating a new charity
   * @param {CreateCharityRequestDto} createCharityRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerCreateCharity(createCharityRequestDto: CreateCharityRequestDto, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerCreateCharity(createCharityRequestDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateExportRequestDto} createExportRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerCreateExport(createExportRequestDto: CreateExportRequestDto, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerCreateExport(createExportRequestDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for creating a new volunteer
   * @param {CreateVolunteerRequestDto} createVolunteerRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerCreateVolunteer(
    createVolunteerRequestDto: CreateVolunteerRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminControllerCreateVolunteer(createVolunteerRequestDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deactivates specified charity. Connected sales will also be deleted.
   * @param {string} charityId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerDeactivateCharity(charityId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerDeactivateCharity(charityId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deactivates specified volunteer. Connected sales will be kept.
   * @param {string} volunteerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerDeactivateVolunteer(volunteerId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerDeactivateVolunteer(volunteerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for fetching all exports
   * @param {string} [take]
   * @param {string} [skip]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerGetAllExports(take?: string, skip?: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerGetAllExports(take, skip, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for verifying the firebase user being admin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerIsLoginValid(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerIsLoginValid(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for getting the public key for a specified sale
   * @param {string} saleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerSalePublicKey(saleId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerSalePublicKey(saleId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for fetching all the sales grouped by charity
   * @param {string} from
   * @param {string} to
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerSalesGroupedByCharities(from: string, to: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerSalesGroupedByCharities(from, to, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for fetching the admin sales statistics
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerSalesStatistics(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerSalesStatistics(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for fetching all sales this billing period
   * @param {any} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerSalesThisBillingPeriod(filter?: any, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerSalesThisBillingPeriod(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for fetching specified volunteer sales statistics
   * @param {string} from
   * @param {string} to
   * @param {string} charityId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerScopedCharitySalesStatistics(
    from: string,
    to: string,
    charityId: string,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminControllerScopedCharitySalesStatistics(from, to, charityId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for fetching specified volunteer sales statistics
   * @param {string} from
   * @param {string} to
   * @param {string} volunteerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerScopedVolunteerSalesStatistics(
    from: string,
    to: string,
    volunteerId: string,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminControllerScopedVolunteerSalesStatistics(from, to, volunteerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for updating a charity
   * @param {string} charityId
   * @param {UpdateCharityRequestDto} updateCharityRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerUpdateCharity(
    charityId: string,
    updateCharityRequestDto: UpdateCharityRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminControllerUpdateCharity(charityId, updateCharityRequestDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerUploadFile(file?: File, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerUploadFile(file, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for fetching specified volunteer sales grouped by charity
   * @param {string} from
   * @param {string} to
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerVolunteerSalesGroupedByCharity(from: string, to: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminControllerVolunteerSalesGroupedByCharity(from, to, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for retrieving pings from the Adyen API
   * @param {WebhookDto} webhookDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adyenControllerWebhook(webhookDto: WebhookDto, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adyenControllerWebhook(webhookDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for getting a charity prefill
   * @param {string} slug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public charityControllerGetCharityPrefill(slug: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .charityControllerGetCharityPrefill(slug, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for getting a charity\'s data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public charityControllerGetMe(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .charityControllerGetMe(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for getting a charity publically
   * @param {string} slug
   * @param {string} salePublicKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public charityControllerGetPublicCharity(slug: string, salePublicKey: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .charityControllerGetPublicCharity(slug, salePublicKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for getting a charity\'s statistics
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public charityControllerGetStatistics(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .charityControllerGetStatistics(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for validating a volunteer id
   * @param {string} volunteerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public charityControllerValidVolunteerId(volunteerId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .charityControllerValidVolunteerId(volunteerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for periodically cleaning the database of old exports and sales.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cronJobControllerPeriodCleanup(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .cronJobControllerPeriodCleanup(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for creating a Customer
   * @param {CreateCustomerDto} createCustomerDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public customerControllerCreateCustomer(createCustomerDto: CreateCustomerDto, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .customerControllerCreateCustomer(createCustomerDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for getting a customer\'s data using its ssid
   * @param {string} ssid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public customerControllerFindOne(ssid: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .customerControllerFindOne(ssid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for updating a Customer
   * @param {string} ssid
   * @param {UpdateCustomerDto} updateCustomerDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public customerControllerUpdateCustomer(
    ssid: string,
    updateCustomerDto: UpdateCustomerDto,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .customerControllerUpdateCustomer(ssid, updateCustomerDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for creating a new payment
   * @param {CreatePaymentDto} createPaymentDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public paymentControllerCreatePayment(createPaymentDto: CreatePaymentDto, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .paymentControllerCreatePayment(createPaymentDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for getting the status of an Adyen payment
   * @param {string} sessionId
   * @param {string} sessionResult
   * @param {string} publicKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public paymentControllerGetAndSetPlanAdyenPaymentStatus(
    sessionId: string,
    sessionResult: string,
    publicKey: string,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .paymentControllerGetAndSetPlanAdyenPaymentStatus(sessionId, sessionResult, publicKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for creating a new sale
   * @param {CreateSaleDto} createSaleDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public saleControllerCreateSale(createSaleDto: CreateSaleDto, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .saleControllerCreateSale(createSaleDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for fetching a sale
   * @param {string} saleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public saleControllerFindOne(saleId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .saleControllerFindOne(saleId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for publically finding a sale
   * @param {string} saleId
   * @param {string} publicKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public saleControllerFindPublicSale(saleId: string, publicKey: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .saleControllerFindPublicSale(saleId, publicKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for signing a sale
   * @param {string} saleId
   * @param {SignSaleDto} signSaleDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public saleControllerSignSale(saleId: string, signSaleDto: SignSaleDto, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .saleControllerSignSale(saleId, signSaleDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for updating a sale
   * @param {string} saleId
   * @param {UpdateSaleDto} updateSaleDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public saleControllerUpdateSale(saleId: string, updateSaleDto: UpdateSaleDto, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .saleControllerUpdateSale(saleId, updateSaleDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for creating spar data and creating the needed associated entities.
   * @param {ManualEntryDto} manualEntryDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public sparControllerCreate(manualEntryDto: ManualEntryDto, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .sparControllerCreate(manualEntryDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for getting spar data using ssid and creating the needed associated entities.
   * @param {string} ssid
   * @param {GetSparDataDto} getSparDataDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public sparControllerFindOne(ssid: string, getSparDataDto: GetSparDataDto, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .sparControllerFindOne(ssid, getSparDataDto, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used for getting a volunteer\'s statistics
   * @param {string} volunteerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public volunteerControllerGetMyStatistics(volunteerId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .volunteerControllerGetMyStatistics(volunteerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public workerControllerExecute(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .workerControllerExecute(options)
      .then(request => request(this.axios, this.basePath));
  }
}
