import { CharityDtoFlowEnum, GetCharityPrefillResponse } from '@expo/api-client';
import { PlanLogin } from './planFlow/PlanLogin';
import { RegularLogin } from './regularFlow/RegularLogin';

export const RenderDynamicFlow = ({ prefill }: { prefill: GetCharityPrefillResponse }) => {
  switch (prefill.flow) {
    case CharityDtoFlowEnum.Plan:
      return <PlanLogin prefill={prefill} />;
    case CharityDtoFlowEnum.Regular:
    case CharityDtoFlowEnum.Ssm:
    default:
      return <RegularLogin prefill={prefill} />;
  }
};
