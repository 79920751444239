import { CharityDtoFlowEnum, GetMyCharityResponse } from '@expo/api-client';
import { PlanHomeFlow } from './planFlow/PlanHomeFlow';
import { RegularHomeFlow } from './regularFlow/RegularHomeFlow';

export const RenderDynamicFlow = ({ chosenCharity }: { chosenCharity: GetMyCharityResponse }) => {
  switch (chosenCharity.flow) {
    case CharityDtoFlowEnum.Plan:
      return <PlanHomeFlow />;
    case CharityDtoFlowEnum.Regular:
    case CharityDtoFlowEnum.Ssm:
    case CharityDtoFlowEnum.Rfsu:
    default:
      return <RegularHomeFlow />;
  }
};
